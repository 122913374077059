<template>
    <section class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100 main-home-menu">
        <ul
            class="top-box bottom-box ui-layout-flex ui-layout-flex--row ui-layout-flex--items_center ui-layout-flex--space_between"
        >
            <router-link
                class="item bg-box ui-cursor--pointer ui-layout-flex ui-layout-flex--row ui-layout-flex--items_center ui-layout-flex--center "
                v-for="menu in topMenus"
                :key="menu.menuId"
                :to="menu.url"
            >
                <i :class="`${menu.icon}`"></i>
                <span class="text ui-font--small">{{ menu.name }}</span>
            </router-link>
        </ul>
        <div class="menu-box bg-box ui-layout-flex ui-layout-flex--column ui-layout-flex--1 ">
            <div
                class="menu-row-box ui-layout-flex ui-layout-flex--row ui-layout-flex--items_start"
                v-for="item in taskCountMenus"
                :key="item.menuId"
            >
                <div class="title ui-font--small">{{ item.name }}</div>
                <ul
                    class="menu-row ui-layout-flex--1 ui-layout-flex ui-layout-flex--row ui-layout-flex--items_center ui-layout-flex--start"
                >
                    <li
                        class="item ui-layout-flex ui-layout-flex--column ui-layout-flex--items_center ui-layout-flex--center"
                        v-for="menu in item.list"
                        :key="menu.menuId"
                    >
                        <el-badge :value="menu.taskCount" :hidden="!menu.taskCount || menu.taskCount < 1">
                            <div class="icon-box">
                                <i :class="`${menu.icon} icon`"></i>
                            </div>
                        </el-badge>
                        <span class="text ui-font--small">{{ menu.name }}</span>
                        <nav class="sub-menu ui-border">
                            <router-link
                                :to="ele.url"
                                class="ui-text-decoration--none ui-font--small ui-link"
                                v-for="ele in menu.list"
                                :key="ele.menuId"
                            >
                                <span class="text ui-font--small">
                                    {{ ele.name }}
                                    <el-badge class="mark" :value="ele.taskCount" :hidden="!ele.taskCount || ele.taskCount < 1" />
                                </span>
                            </router-link>
                        </nav>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex';

const taskCountId = new Map([
    [188, 'workflowCount'],
    [330, 'plannedCount'],
    [331, 'unPlannedCount'],
    [332, 'productionCount'],
    [339, 'elecOverhaulPlanned'],
    [340, 'elecOverhaulUnplanned'],
    [341, 'elecProduction'],
    [363, 'elecWorkTicket'],
    [367, 'elecUrgentRepairs'],
    [375, 'elecDispatcnFlaw'],
    [376, 'elecDispatcnHidden'],
    [378, 'elecTaskIssue'],
]);

const host = 'https://bs-elec.ynlxkj.cn/big-screen/';
export default {
    name: 'MainHomeMenu',
    data() {
        return {
            menuGroup: [],
            menus: [],
            topMenus: [
                {
                    icon: 'el-icon-money',
                    menuId: 1,
                    name: '收支情况',
                    url: '/visualization/IncomeAndExpenditure'
                },
                {
                    icon: 'el-icon-close-notification',
                    menuId: 2,
                    name: '运行情况',
                    url: '/visualization/running'
                },
                {
                    icon: 'el-icon-wallet',
                    menuId: 3,
                    name: '营收情况',
                    url: '/visualization/profitandsell'
                },
                {
                    icon: 'el-icon-shopping-bag-1',
                    menuId: 4,
                    name: '购售电业务情况',
                    url: '/visualization/Business'
                }
            ],
            bottomMenus: [],
        };
    },
    computed: {
        ...mapGetters(['userMenu', 'userInfo', 'taskCount']),
        taskCountMenus() {
            const menus = this.menus;
            menus.forEach(item => {
                item.list.forEach(menu => {
                    menu.taskCount = 0;
                    menu.list.forEach(subMenu => {
                        if (taskCountId.has(subMenu.menuId)) {
                            subMenu.taskCount = this.taskCount[taskCountId.get(subMenu.menuId)] || 0;
                            menu.taskCount += subMenu.taskCount;
                        }
                    });
                });
            });
            return menus;
        }
    },
    methods: {
        async loadMenus() {
            let menus = [];
            if (this.userInfo.orgList.length > 0) {
                let org = this.userInfo.orgList[0];
                if (org.depth === 1) {
                    this.menuGroup.forEach(m => {
                        let list = [];
                        m.value.split(',').forEach(e => {
                            let menu = this.userMenu.filter(i => i.name === e);
                            if (menu.length > 0) {
                                list.push(menu[0]);
                            }
                        });
                        menus.push({
                            icon: null,
                            menuId: m.name,
                            name: m.name,
                            list
                        });
                    });
                } else if (org.depth > 2) {
                    let orgResp = await this.$client.getDepartmentList();
                    let orgList = orgResp.list;
                    let findDepth2Parent = (orgs, parentId) => {
                        let result = orgs.filter(e => e.id === parentId);
                        if (result.length > 0) {
                            if (result[0].depth === 2) {
                                return result[0];
                            }
                            return findDepth2Parent(orgs, result[0].parentId);
                        }
                        return null;
                    };
                    org = findDepth2Parent(orgList, org.parentId);
                }

                if (org.depth === 2) {
                    let orgName = org.name;
                    let orgMenu = this.menuGroup.filter(i => i.name === orgName);
                    if (orgMenu.length > 0) {
                        let list = [];
                        orgMenu[0].value.split(',').forEach(e => {
                            let menu = this.userMenu.filter(i => i.name === e);
                            if (menu.length > 0) {
                                list.push(menu[0]);
                            }
                        });

                        menus.push({
                            icon: null,
                            menuId: orgName,
                            name: orgName,
                            list
                        });
                    }

                    let commonMenu = this.menuGroup.filter(i => i.name === '公共');
                    if (commonMenu.length > 0) {
                        let list = [];
                        commonMenu[0].value.split(',').forEach(e => {
                            let menu = this.userMenu.filter(i => i.name === e);
                            if (menu.length > 0) {
                                list.push(menu[0]);
                            }
                        });

                        menus.push({
                            icon: null,
                            menuId: commonMenu[0].name,
                            name: commonMenu[0].name,
                            list
                        });
                    }
                }
            }
            this.menus = menus;
        },
    },
    created() {
        this.$client.listDictionaryChildren('menu_group').then(res => {
            this.menuGroup = res.data;
            this.loadMenus();
        });
    }
};
</script>

<style scoped lang="less">
@import '~@/assets/style/common.less';

.main-home-menu {
    padding: 20px 40px;
    box-sizing: border-box;
    background: url(~@/assets/images/home-menu/background.png) right no-repeat;
    background-size: 100% 100%;

    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .bg-box {
        border: 1px solid @bg-diliver;
        background: linear-gradient(47deg, rgba(23, 30, 51, 0.1) 0%, rgba(28, 36, 59, 0.1) 100%);
        border-radius: 6px;
        backdrop-filter: blur(8px);

        &:hover {
            border-color: #396fff;
        }
    }

    .top-box {
        padding: 10px 0 !important;
    }

    .menu-box {
        margin: 10px 0;
        z-index: 5;
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        &:hover {
            border-color: @bg-diliver;
        }

        .menu-row-box {
            .title {
                border-left: 4px #ff7260 solid;
                padding-left: 4px;
                margin-left: 20px;
                margin-top: 30px;
                width: 70px;
            }

            &:nth-child(2n) .title {
                border-color: #8568ff;
            }

            .menu-row {
                flex-wrap: wrap;

                .item {
                    position: relative;
                    width: calc(100% / 8);
                    padding: 30px 0;

                    .icon-box {
                        width: 50px;
                        height: 50px;
                        margin-bottom: 16px;
                        border-radius: 50%;
                        border: 1px solid rgba(255, 255, 255, 0.4);
                        text-align: center;

                        .icon {
                            font-size: 20px;
                            line-height: 50px;
                        }
                    }

                    .sub-menu {
                        background-color: rgba(@bg-diliver, 0.9);
                        border-color: #396fff;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 5px;
                        max-height: 100%;
                        overflow-y: auto;
                        z-index: 10;
                        transform: translateY(-50%) scale(0);
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.3s;

                        .text {
                            display: block;
                            line-height: 30px;
                            text-align: center;

                            &:hover {
                                background-color: @bg-btn-light;
                                border-radius: 4px;
                            }

                            .mark {
                                height: 20px;
                                margin-left: 4px;
                                transform: scale(0.9);
                            }
                        }
                    }

                    &:hover .sub-menu {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(-50%) scale(1);
                        transition: all 0.3s;
                    }
                }
            }
        }
    }

    .bottom-box {
        padding: 0 20px;
        padding-bottom: 40px;
        z-index: 0;

        .item {
            height: 44px;
            flex: 1;
            color: #fff;
            text-decoration: none;

            &:not(:last-child) {
                margin-right: 30px;
            }

            .icon {
                width: 14px;
                height: 14px;
            }

            .text {
                margin-left: 10px;
            }
        }
    }
}
</style>
